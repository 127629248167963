<template>
  <div class="login-container">
    <el-row>
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="14"
              :xl="14">
        <div style="color: transparent">占位符</div>
      </el-col>
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="10"
              :xl="10">
        <el-form ref="loginForm"
                 :model="loginForm"
                 :rules="loginRules"
                 class="login-form"
                 auto-complete="off"
                 label-position="left">
          <div class="title-container">
            <h3 class="title">后台管理</h3>
          </div>

          <el-form-item prop="username">
            <span class="svg-container">
              <svg-icon icon-class="user" />
            </span>
            <el-input ref="username"
                      v-model="loginForm.username"
                      placeholder="Username"
                      name="username"
                      type="text"
                      tabindex="1"
                      auto-complete="off" />
          </el-form-item>

          <el-form-item prop="password">
            <span class="svg-container">
              <svg-icon icon-class="password" />
            </span>
            <el-input :key="passwordType"
                      ref="password"
                      v-model="loginForm.password"
                      :type="passwordType"
                      placeholder="Password"
                      name="password"
                      tabindex="2"
                      auto-complete="on"
                      @keyup.enter.native="handleLogin" />
            <span class="show-pwd"
                  @click="showPwd">
              <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
            </span>
          </el-form-item>

          <el-button :loading="loading"
                     type="primary"
                     class="login-btn"
                     @click.native.prevent="handleLogin">登录</el-button>

          <!-- <div class="tips">
            <span style="margin-right: 20px">username: admin</span>
            <span> password: any</span>
          </div>
          <div class="tips">
            <span style="margin-right: 18px">Username : editor</span>
            <span>Password : any</span>
          </div> -->
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { validUsername } from '@/utils/validate'

export default {
  name: 'Login',
  data() {
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error('请输入正确的用户名'))
    //   } else {
    //     callback()
    //   }
    // }
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur' }],
        password: [
          { required: true, trigger: 'blur', validator: validatePassword },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true,
    },
  },
  methods: {
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$api
            .login(this.loginForm)
            .then((data) => {
              data.token && localStorage.setItem('token', data.token)

              this.$api.getUserInfo().then((res) => {
                let roles = res.roles.join(',')
                if(roles && roles.indexOf('guest') != -1){
                  console.log(1);
                  this.$router.push({ path: '/gallery' })
                } else {
                  this.$router.push({ path: this.redirect || '/' }) // 登录成功之后重定向到首页
                }
              })

              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              this.$message.error(err) // 登录失败提示错误
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  mounted(){
    // localStorage.removeItem('token')
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
$base-font-color: #606266;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 57px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      // color: $light_gray;
      color: $base-font-color;
      height: 57px;
      // caret-color: $cursor;
      caret-color: $base-font-color;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    background: #f6f4fc;
    border-radius: 5px;
    color: #454545;

    &__error {
      position: absolute;
      top: 100%;
      left: 18px;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
$base-font-color: #606266;

.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  background: url('~@/assets/login_images/background.jpg') center center fixed
    no-repeat;
  background-size: cover;
  overflow: hidden;

  .login-form {
    position: relative;
    // width: 520px;
    max-width: 100%;
    padding: calc((100vh - 425px) / 2) 10% 10%;
    overflow: hidden;
  }

  .login-btn {
    width: 100%;
    height: 57px;
    margin-bottom: 30px;
    font-size: 18px;
  }

  .tips {
    font-size: 14px;
    color: $base-font-color;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 44px;
      // color: $light_gray;
      color: rgba(14, 18, 26, 1);
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: 500;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}
</style>
