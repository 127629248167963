<template>

  <div>
    <div class="warp">
      <div v-for="(item,index) in images"
           v-viewer="options"
           style="display: flex; flex-direction: column;"
           class="image"
           :key="index">
        <img v-for="(img, imdex) in item.fileList"
             v-show="imdex == 0"
             :key="imdex"
             :src="getImgNailSrc(img)"
             :data-source="getImgSrc(img)">
        <div style="font-size: 14px;">
          <div style="">Product: <span style="color: rgb(76, 76, 240)">{{item.product_name}}</span></div>
          <el-tooltip class="item"
                      effect="dark"
                      :content="item.product_weight"
                      placement="bottom-start">
            <div style="max-width: 200px;text-overflow: ellipsis;white-space: nowrap;word-break:break-all; overflow:hidden; ">Weight: <span style="color: rgb(76, 76, 240)">{{ item.product_weight }}</span></div>

          </el-tooltip>
        </div>
      </div>
    </div>
    <div style="padding: 20px 0;text-align: right;">
      <el-pagination background
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page.sync="pageIndex"
                     :page-sizes="[50, 100]"
                     layout="prev, pager, next,sizes"
                     :page-size="pageSize"
                     :total="total">
      </el-pagination>
    </div>

  </div>
</template>
<script>
import 'viewerjs/dist/viewer.css'
import { directive as viewer } from 'v-viewer'
export default {
  directives: {
    viewer: viewer({
      debug: true,
    }),
  },
  data() {
    return {
      options: {
        toolbar: true,
        url: 'data-source',
      },
      images: [],
      // options: {
      //   inline: false,
      //   button: true,
      //   navbar: true,
      //   title: true,
      //   toolbar: true,
      //   tooltip: true,
      //   movable: true,
      //   zoomable: true,
      //   rotatable: true,
      //   scalable: true,
      //   transition: true,
      //   fullscreen: true,
      //   keyboard: true,
      //   url: 'data-source',
      // },
      pageIndex: 1,
      pageSize: 50,
      total: 0,
    }
  },
  methods: {
    getImgSrc(item) {
      return process.env.VUE_APP_BASE_API + 'public/' + item
    },
    getImgNailSrc(item) {
      var period = item.lastIndexOf('.')
      var pluginName = item.substring(0, period)
      var fileExtension = item.substring(period + 1)
      return (
        process.env.VUE_APP_BASE_API +
        'public/' +
        pluginName +
        '_200x.' +
        fileExtension
      )
    },
    inited(viewer) {
      this.$viewer = viewer
    },
    handleSizeChange(val) {
      this.pageIndex = 1
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.loadData()
    },
    loadData() {
      this.$api
        .getAllProduct({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          res.data.forEach((element) => {
            element.fileList = element.image_path.split(',')
          })
          this.images = res.data
          this.total = res.count
        })
    },
  },
  mounted() {
    this.loadData()
  },
}
</script>
<style scoped lang="scss">
.warp {
  display: flex;
  flex-wrap: wrap;
}
.viewer {
  display: flex;
  flex-wrap: wrap;
}
.image {
  cursor: pointer;
  margin: 5px;
  display: inline-block;
  img {
    height: 200px;
    object-fit: contain;
  }
}
</style>