import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import VueRouter from 'vue-router'
//引入路由器
import router from './router'
import getPageTitle from '@/utils/get-page-title'
import apiFun from "./request/api";
Vue.prototype.$api = apiFun;//请求接口api

Vue.use(ElementUI, { size: 'medium' })
Vue.use(VueRouter)
const whiteList = ['/login']

function getToken() {
  return !!localStorage.getItem('token')
}
// 在全局钩子router.beforeEach中拦截路由
router.beforeEach(async (to, from, next) => {
  // set page title
  document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // if (localStorage.getItem('roles').indexOf('guest') != -1) {
    //   console.log(1);
    //   next('/gallery')
    // } else {
    //   next()
    // }
    next()
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next('/login')
    }
  }
})

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router: router
}).$mount('#app')
