<template>
  <div>
    <el-upload class="upload-demo" drag action="http://localhost:8081/api/upload" name="file" multiple
      :on-preview="handlePreview" :on-remove="handleRemove" show-file-list>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {

    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file);
    },
    handlePreview(file) {
      console.log(file);
      window.open('http://localhost:8081/' + file.response.fileList.filename)
    }
  }

}
</script>

<style scoped></style>
