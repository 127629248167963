<template>
<div>
  <div class="header">
    
  </div>
  <div class="content">
    <imageList></imageList>
  </div>
  <div class="footer"></div>
</div>
</template>

<script>
import imageList from './imageList.vue'
export default {
name:'',
components: {
  imageList
},
props: {},
data() {
//这里存放数据
return {};
},
computed: {},
watch: {},
methods: {},
created() {},
mounted() {},
};
</script>
<style scoped>
.content{
  padding:  20px 20%;
}
</style>