import VueRouter from 'vue-router'
//引入组件
import HelloWorld from '../components/HelloWorld'
import Login from '../views/login/index'
import home from '../views/home/index'
import gallery from '../views/gallery/index'

//创建并暴露一个路由器
export default new VueRouter({
  routes: [
    {
      path: '/login',
      component: Login,
      meta: {
        title: '登录',
        icon: 'nested'
      }
    },
    {
      path: '/',
      component: home,
      meta: {
        title: '首页',
        icon: 'nested'
      }
    },
    {
      path: '/gallery',
      component: gallery,
      meta: {
        title: '展示',
        icon: 'nested'
      }
    }
  ]
})