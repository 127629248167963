import http from './http.js';

let apiFun = {};

apiFun.getUserInfo = function (params) {
  return http.get('/api/getUserInfo')
}

apiFun.login = function (params) {
  return http.post('/public/login', params)
}

apiFun.getAllProduct = function (params) {
  return http.get('/api/getAllProduct', params)
}
apiFun.deleteProductById = function (params) {
  return http.delete('/api/deleteProductById', params)
}

apiFun.deleteFile = function (params) {
  return http.get('/api/deleteFile', params)
}

apiFun.addProduct = function (params) {
  return http.post('/api/addProduct', params)
}

export default apiFun;

