<template>
  <div class="warp">
    <div class="header">
      <div style="font-size: 26px;">后台管理</div>
      <div><el-button type="text" @click="showGallery">产品展示</el-button></div>
    </div>
    <div class="main">
      <!-- <div class="left">
        <el-menu default-active="2"
                 class="el-menu-vertical-demo"
                 @open="handleOpen"
                 @select="select"
                 @close="handleClose">

          <el-menu-item index="1">
            <i class="el-icon-menu"></i>
            <span slot="title">产品管理</span>
          </el-menu-item>
        </el-menu>
      </div> -->
      <div class="right">
        <div style="text-align: right;">
          <el-button type="primary"
                     icon="el-icon-plus"
                     @click="handleAdd">新增</el-button>
        </div>
        <el-table :data="tableData"
                  style="width: 100%">
          <el-table-column type="index"
                           width="50">
          </el-table-column>
          <el-table-column prop="product_name"
                           label="品名">
          </el-table-column>
          <el-table-column prop="product_no"
                           label="编号">
          </el-table-column>
          <el-table-column prop="product_weight"
                           label="克重">
          </el-table-column>
          <el-table-column prop="image_path"
                           label="图片">
            <template slot-scope="scope">
              <div v-for="(item,index) in scope.row.fileList"
                   :key="index">
                <el-button type="text"
                           @click="showPic(item)"
                           disable-transitions>{{item}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="addTime"
                           label="创建日期">
            <template slot-scope="scope">
              <div>
                {{ getDate1(new Date(scope.row.addTime)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- <el-button size="mini"
                         @click="handleEdit(scope.$index, scope.row)">编辑</el-button> -->
              <el-button size="mini"
                         type="danger"
                         @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="padding: 20px 0;text-align: right;">
          <el-pagination background
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="pageIndex"
                         :page-sizes="[10, 20, 50, 100]"
                         layout="prev, pager, next,sizes"
                         :page-size="pageSize"
                         :total="total">
          </el-pagination>
        </div>

      </div>
    </div>

    <el-dialog title="新增"
               width="40%"
               :close-on-click-modal="false"
               :visible.sync="dialogFormVisible">
      <el-form :model="form"
               ref="form"
               :rules="rules">
        <el-form-item label="品名"
                      prop='product_name'
                      :label-width="formLabelWidth">
          <el-input v-model="form.product_name"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="编号"
                      prop='product_no'
                      :label-width="formLabelWidth">
          <el-input v-model="form.product_no"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="克重"
                      prop='product_weight'
                      :label-width="formLabelWidth">
          <el-input v-model="form.product_weight"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上传图片"
                      :label-width="formLabelWidth">
          <el-upload class="upload-demo"
                     drag
                     action="http://47.100.55.93:8081/api/upload"
                     name="file"
                     :headers="headers"
                     :accept="accept"
                     multiple
                     :on-preview="handlePreview"
                     :on-remove="handleRemove"
                     :on-success="handleSuccess"
                     :file-list="fileList"
                     show-file-list>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip"
                 slot="tip">只能上传jpg/png文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      rules: {
        product_name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      accept: '.jpg,.png,.jpeg',
      tableData: [],
      dialogFormVisible: false,
      form: {
        product_name: '',
        product_no: '',
        product_weight: '',
      },
      formLabelWidth: '120px',
      fileList: [],
    }
  },
  computed: {},
  watch: {},
  methods: {
    showGallery(){
      this.$router.push('/gallery')
    },
    getDate1(date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? '0' + d : d
      let h = date.getHours()
      h = h < 10 ? '0' + h : h
      let M = date.getMinutes()
      M = M < 10 ? '0' + M : M
      let s = date.getSeconds()
      s = s < 10 ? '0' + s : s
      let dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M + ':' + s
      return dateTime
    },
    submitForm() {
      let fileStr = this.fileList.map((item) => {
        return item.response.fileList.filename
      })
      // let thumbnailStr = this.fileList.map((item) => {
      //   return item.response.fileList.thumbnail_name
      // })
      console.log(this.form, 'form')
      let obj = {
        ...this.form,
        image_path: fileStr.join(','),
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.dialogFormVisible = false
          this.$api.addProduct(obj).then((res) => {
            this.loadData()
          })
        } else {
          return false
        }
      })
    },
    handleSizeChange(val) {
      this.pageIndex = 1
      this.pageSize = val
      this.loadData()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.loadData()
    },
    // select(key, keyPath) {
    //   if (key == 2) {
    //   }
    // },
    // handleOpen(key, keyPath) {},
    // handleClose(key, keyPath) {},
    handleAdd() {
      this.dialogFormVisible = true
    },
    showPic(item) {
      window.open(process.env.VUE_APP_BASE_API + 'public/' + item)
    },
    handlePreview(file) {
      window.open(
        process.env.VUE_APP_BASE_API +
          'public/' +
          file.response.fileList.filename
      )
    },
    handleSuccess(response, file, fileList) {
      this.fileList = fileList
    },
    handleRemove(file, fileList) {
      this.fileList = this.fileList.filter((v) => v.uid !== file.uid)
      this.$api
        .deleteFile({
          path: file.response.fileList.path,
        })
        .then((res) => {})
    },
    loadData() {
      this.$api
        .getAllProduct({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        })
        .then((res) => {
          res.data.forEach((element) => {
            element.fileList = element.image_path.split(',')
          })
          this.tableData = res.data
          this.total = res.count
        })
    },
    handleEdit(index, row) {
      console.log(index, row)
    },
    handleDelete(index, row) {
      this.$confirm('此操作将删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$api
            .deleteProductById({
              id: row.id,
            })
            .then((res) => {
              this.$message({
                message: res.message,
                type: 'success',
              })
              this.loadData()
            })
        })
        .catch(() => {})
    },
  },
  created() {},
  mounted() {
    this.loadData()
  },
}
</script>
<style scoped>
.el-menu {
  height: 100%;
}
.warp {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  height: 60px;
  min-height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background-color: rgb(235, 235, 235);
}
.main {
  flex: 1;
  display: flex;
  width: 100%;
}

.left {
  width: 200px;
}

.right {
  flex: 1;
  padding: 20px;
}
</style>